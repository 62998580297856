<template>
<div class="flex justify-evenly w-full 2xl:w-2/3  ml-16">
  <div v-for="(step, idx) in totalSteps" :key="idx" class="flex flex-auto justify-start items-center">
<!--    <div class="w-1/2 h-full border-2 border-gray-400 rounded-full flex justify-center items-center">-->
<!--      <div v-if="step === currentStep" class="w-1/3 h-1/3 bg-gray-500 rounded-full"></div>-->
<!--    </div>-->
    <svg viewBox="0 0 100 100" class="border-2">
      <circle cx="50" cy="50" r="20" :stroke="[step == currentStep ? 'black' : 'gray']" :stroke-width="[step == currentStep ? '3' : '2']" fill="" fill-opacity="0" />
    </svg>
    <div  class="h-1 w-full " >
      <div v-if="step !== totalSteps" class="bg-gray-300 w-full h-full" />
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: "StepIndicator",
  props: {
    totalSteps: Number,
    currentStep: Number
  }
}
</script>

<style scoped>

</style>