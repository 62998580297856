<template>
  <div class="w-full grid grid-cols-11 gap-4 p-2">
    <div v-for="(form, idx) in formList" :key="idx" class="col-span-11 grid grid-cols-11 gap-4">
      <SelectInput heading="Role" :options="roles" :value="form.role" @update:value="form.role=$event"  class="col-span-2"/>
      <SelectInput heading="Department" :options="departments" :value="form.department" @update:value="form.department=$event"  class="col-span-2"/>
      <DateInput heading="Year started"  class="col-span-2"/>
      <DateInput heading="Year left" class="col-span-2"/>
      <TrashIcon @click="removeLine(idx)"  class="w-6 mt-8 col-span-1"/>
    </div>
    <button class="text-medium font-medium tracking-wider mx-4 my-4 col-span-2" @click="addLine">+ Add role</button>
    <div class="flex justify-end items-center my-6 col-start-10">
      <button @click="$emit('submit', formList)" type="button" class="mr-2 mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        Submit
      </button>
      <button @click="$emit('close')" type="button" class="mx-2 mt-4 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white text-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
    </div>
    <div class="col-span-11">
    </div>
  </div>
</template>

<script>
import { TrashIcon } from '@heroicons/vue/outline/esm'
import SelectInput from "@/components/inputs/SelectInput";
import DateInput from "@/components/inputs/DateInput";
import {ref} from "vue";
import {useStore} from "vuex";
export default {
  name: "ProductionMemberForm",
  components: {DateInput, SelectInput, TrashIcon},
  props: {
    selectedRoles: Array
  },
  setup(props) {
    const store = useStore()
    const formList = ref([])

    function addLine(){
      formList.value.push({
        role: '',
        department: '',
        meta: {
          created: true,
          edited: false
        }
      })
    }

    async function removeLine(idx){
      let role = formList.value.splice(idx, 1)[0]
      if(!role.meta.created){
        await store.dispatch('deleteProductionMember', role.prodMemId)
        store.commit('removeProductionMember', role.prodMemId)
      }
    }

    if(props.selectedRoles){
      for (let i = 0; i < props.selectedRoles.length; i++){
        formList.value.push({
          role:props.selectedRoles[i].title,
          department:props.selectedRoles[i].department,
          id:props.selectedRoles[i].id,
          prodMemId: props.selectedRoles[i].prodMemId,
          meta: {
            created: false,
            edited: false
          }
        })
      }
    } else {
      addLine()
    }


    return {
      formList,
      addLine,
      removeLine,
      departments: store.getters.getDepartmentTypes,
      roles: store.getters.getProfileJobs
    }
  }
}
</script>
