<template>
  <div class="flex flex-col bg-white shadow-lg w-full">
    <div class="">
      <ImageInput class="w-1/2 mx-auto m-6" :on-change="fileChange" :preview="false" :previews="picturePreviews"/>
    </div>
    <div v-for="(e, idx) in form" :key="idx" class="flex m-6 justify-evenly">
      <SmallTextInput placeholder="Photographer" :value="e.photographer" @update:value="e.photographer=$event"/>
      <DateInput :value="e.date" @update:value="e.date=$event"/>
      <SmallTextInput placeholder="Location" :value="e.location" @update:value="e.location=$event"/>
      <img :src="e.preview" class="w-16 rounded-md"/>
    </div>
    <div class="flex justify-center items-center my-10">
      <button @click="submitForm" class="mx-2 bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Upload</button>
    </div>
  </div>
</template>

<script>
import ImageInput from "@/components/inputs/ImageInput";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import {reactive, ref} from "vue";
import DateInput from "../inputs/DateInput";
export default {
  name: "MultiplePhotoForm",
  components: {DateInput, SmallTextInput, ImageInput},
  props: {
    onSubmit: Function
  },
  setup() {
    const picturePreviews = ref([])
    const form = reactive([])

    return {
      form,
      picturePreviews,
      fileChange: async(files) => {
        for (let i=0; i < files.length; i++){
          picturePreviews.value.push(URL.createObjectURL(files[i]))
          form.push({
            image: files[i],
            preview: URL.createObjectURL(files[i]),
            photographer: '',
            date: '',
            location: ''
          })
        }
      },
    }
  },
  methods: {
    async submitForm(){
      await this.$props.onSubmit(this.form)
      this.form.length = 0
    }
  }
}
</script>
