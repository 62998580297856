<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <div class="flex items-start flex-col">
      <div v-if="memberWidgetOn" class="inset-x-64 top-40 z-30 fixed">
        <EntityMemberWidget :profile=selectedPerson @close="closeMemberWidget" @selected="selectedPerson=$event">
          <ProductionMemberForm v-if="selectedPerson && selectedPerson.id" :selectedRoles="selectedPersonRoles" @close="closeMemberWidget"  @submit="submitMember" class="col-span-5"/>
        </EntityMemberWidget>
      </div>
      <DynamicTable :filters="[]" @next="onNextPage" @prev="onPreviousPage" class="w-full mt-10 " :link="link" :update="update" :button="button">
        <button class="text-lg font-medium tracking-wider mx-4" @click.stop="memberWidgetOn=!memberWidgetOn">+ Add member</button>
      </DynamicTable>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import { useRouter} from "vue-router";


import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";
import EntityMemberWidget from "@/components/widgets/entityMemberWidget/EntityMemberWidget";
import ProductionMemberForm from "@/components/widgets/entityMemberWidget/forms/ProductionMemberForm";

export default {
  name: "ProductionMembers",
  components: {ProductionMemberForm, EntityMemberWidget, DynamicTable},
  setup() {
    const store = useStore()
    const router = useRouter()
    store.dispatch('dynamicTableLoadProductionMembers')

    // =================Dynamic Table================= //
    function onProfileView(prodMemId){
      router.push({ name: 'Profile', params: { id: findProfileId(prodMemId)[0].profileId }})
    }

    function findProfileId(id){
      return store.getters.getDynamicTableItems.filter( el => el.id == id)
    }

    async function update(){
      await store.dispatch('dynamicTableLoadProductionMembers')
    }

    async function onNextPage() {
      await store.dispatch('productionMembersPageNext')
    }

    async function onPreviousPage() {
      await store.dispatch('productionMembersPagePrevious')
    }
    // ============================================== //
    // =================Member Widget================ //
    function getAllMemberRoles(prodMemId){
      const profile = store.getters.getProductionMemberProfile(prodMemId)
      const roles = store.getters.getProductionMemberRoles(profile.id)
      selectedPerson.value = profile
      selectedPersonRoles.value = roles
      selectedProdMemId.value = prodMemId
      memberWidgetOn.value = true
    }

    function closeMemberWidget(){
      if(memberWidgetOn.value===true){
        memberWidgetOn.value = false
        selectedProdMemId.value = null
        selectedPersonRoles.value = []
        selectedPerson.value = {}
      }
    }
    async function submitMember(formList){
      for(let i = 0; i < formList.length; i++){
        let form = formList[i]
        if(form.meta.created){
          await store.dispatch('createProductionMember', {
            productionId: store.getters.getProductionId,
            profileId: selectedPerson.value.id,
            jobTitle: form.role,
            jobDepartment: form.department
          })
        } else {
          await store.dispatch('editProductionMember', {
            id: form.prodMemId,
            jobTitle: form.role,
            jobDepartment: form.department
          })
        }
      }
      closeMemberWidget()
    }
    const selectedProdMemId = ref(null)
    const selectedPerson = ref({})
    const selectedPersonRoles = ref([])
    const memberWidgetOn = ref(false)
    // ============================================== //

    return {
      selectedPerson,
      memberWidgetOn,
      selectedPersonRoles,
      update,
      onNextPage,
      onPreviousPage,
      submitMember,
      closeMemberWidget,
      link: computed(() => {
        return {func: onProfileView, label: "View Profile", fieldName:'Name'}
      }),
      button: computed(() => {
        return {func: getAllMemberRoles, label: "Edit"}
      }),
      people: computed(() => store.getters.getCardFormattedPeople.slice(0, 4)),
    }
  },
  unmounted() {
    this.$store.commit('resetDynamicTableState')
  }
}
</script>
