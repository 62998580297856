<template>
<div class="flex flex-col items-center">
  <h2 class="text-4xl font-bold mt-10 mb-6">{{title}}</h2>
  <h3 class="text-lg font-medium text-gray-500">{{subTitle}}</h3>
  <StepIndicator :total-steps="totalSteps" :current-step="currentStep" class="mt-6"/>
</div>
</template>

<script>
import StepIndicator from "@/components/widgets/creationStepIndicator/StepIndicator";
export default {
  name: "CreationStepIndicator",
  components: {StepIndicator},
  props: {
    totalSteps: Number,
    currentStep: Number,
    title: String,
    subTitle: String
  }
}
</script>

<style scoped>

</style>