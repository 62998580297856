<template>
  <div class="bg-white w-full grid grid-cols-5">
    <XIcon @click="$emit('close')" class="absolute right-0 w-10 text-gray-400 cursor-pointer" />
    <ProfileSearchWidget :profile="profile" @selected="$emit('selected', $event)" class="col-span-5 h-16" />
    <slot></slot>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'
import ProfileSearchWidget from "@/components/widgets/entityMemberWidget/ProfileSearchWidget";
export default {
  name: "EntityMemberWidget",
  components: {ProfileSearchWidget, XIcon},
  props: {
    profile: Object
  }
}
</script>
