<template>
  <div class="w-full grid grid-cols-8 gap-4">
      <div class="col-span-5">
            <div v-if="create" class="mt-12">
              <div >
                <h3 class=" bg-white my-2 p-2 text-lg leading-6 font-medium text-gray-900 dark:text-white">Production</h3>
                <p class="bg-white my-2 p-2 mt-1 text-sm text-gray-500 dark:text-white">This information will be displayed publicly so be careful what you share.</p>
              </div>
              <div class="flex justify-between bg-white py-6 px-4">
                <div class="grid grid-cols-3 gap-6 w-2/3">
                  <SmallTextInput placeholder="Production Title" :value="form.title" @update:value="form.title=$event" :errors="v$.title.$errors" />
                  <SmallTextInput placeholder="Short name" :value="form.shortName" @update:value="form.shortName=$event" :errors="v$.shortName.$errors"/>
                  <SmallTextInput placeholder="Venue" class="border-red-300 border-2"/>
                  <SmallTextInput placeholder="Original work" class="border-red-300 border-2"/>
                </div>
                <ImageCircleInput :file-change="fileChange" :logo-preview="logoPreview" />
              </div>
            </div>
            <LargeTextInput heading="About this production" :value="form.description" @update:value="form.description=$event" class="my-6"/>
            <LargeTextInput heading="Synopsis" :value="form.synopsis" @update:value="form.synopsis=$event" class="my-6"/>
            <div class="w-full bg-white">
              <button @click="photoPickerOn=true" class="flex justify-center font-medium pt-6">
                <PencilIcon class="w-6 mx-2"/>
                Select photos to show in photo preview
              </button>
              <PhotoPicker v-if="photoPickerOn" @submit="submitPreviewPhotos" @close="photoPickerOn=false" header="Select five pictures to show on the production profile" :max="5" :images="allImages" />
              <ImageDisplay v-if="images.length > 0" :images="images" />
            </div>
        <ProductionPeople :people="currentProduction.productionMembers" :edit="true"/>
      </div>
        <div class="col-span-3 mt-6">
          <ProductionAbout :production="currentProduction" :edit="true" />
          <ProductionWebsites :websites="currentProduction.websites" :edit="true"/>
          <ProductionReviews :reviews="currentProduction.reviews" :edit="true" />
        </div>
    <div class=" flex justify-end col-span-8">
      <button v-if="create" @click="router.push({name:'Productions'})" type="submit" class="mr-2 bg-white-600 border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
        Cancel
      </button>
      <button v-if="create" @click="onSubmit" type="submit" class="bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 dark:text-white dark:bg-backgroundDark">
        Save and continue
      </button>
    </div>


  </div>
</template>

<script>
import {useStore} from "vuex";
import {reactive, computed, ref} from "vue";
import {useRouter} from "vue-router";
import {required} from '@vuelidate/validators'
import { PencilIcon } from '@heroicons/vue/outline'
import useVuelidate from "@vuelidate/core";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import LargeTextInput from "@/components/inputs/LargeTextInput";
import ImageDisplay from "@/components/widgets/ImageDisplay";
import ImageCircleInput from "@/components/inputs/ImageCircleInput";
import PhotoPicker from "@/components/widgets/photoPicker/PhotoPicker";
import ProductionAbout from "@/components/productions/components/ProductionAbout";
import ProductionWebsites from "@/components/productions/components/ProductionWebsites";
import ProductionReviews from "@/components/productions/components/ProductionReviews";
import ProductionPeople from "@/components/productions/components/ProductionPeople";

export default {
  name: "ProductionInfo",
  components: {
    ProductionPeople,
    ProductionReviews,
    ProductionWebsites,
    ProductionAbout,
    PhotoPicker, ImageCircleInput, ImageDisplay, LargeTextInput, SmallTextInput, PencilIcon},
  props: {
    submit: Boolean,
    create: Boolean
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const currentProduction = store.getters.getProduction
    const form = reactive({
      title: currentProduction.title,
      shortName: currentProduction.shortName,
      datePremiered: currentProduction.datePremiered,
      finalPerformance: currentProduction.finalPerformance,
      totalAudience: currentProduction.totalAudience,
      totalPerformances: currentProduction.totalPerformances,
      description: currentProduction.description,
      synopsis: currentProduction.synopsis,
      // venue: currentProduction.venue,
      workType: currentProduction.workType,

    })

    const rules = {
      title: {required},
      shortName: {required},
    }

    const v$ = useVuelidate(rules, form, {$lazy: true})
    const logoPreview = ref(form.logo)
    const photoPickerOn = ref(false)
    return {
      v$,
      currentProduction,
      photoPickerOn,
      form,
      store,
      router,
      workTypes: computed(() => store.getters.getWorkTypes),
      images: computed(() => store.getters.getPreviewProductionImages),
      allImages: computed(() => store.getters.getProductionImages),
      logoPreview,
      fileChange: async(name, files) => {
        form.logo = files[0]
        logoPreview.value = URL.createObjectURL(form.logo)
      },
      submitPreviewPhotos(photos){
        const imageIds = photos.map(obj => obj.id)
        store.dispatch('setProductionPreviewPictures', {imageIds, productionId:store.getters.getProductionId})
      }
    }
  },
  watch: {
    submit: function(newVal){
      if(newVal) this.onSubmit()
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$error) return
      this.$emit('submitted', this.form)
      // await this.$router.push({ name: 'Production', params: { id: this.$store.getters.getProductionId }})
    }
  }

}
</script>
