<template>
  <div class="w-full flex flex-col">
    <label class="block text-sm font-medium text-gray-700 dark:text-white dark:bg-primaryDark">
      {{ heading }}
    </label>
    <div  @change="onChange($event.target.files)" ref="dropbox" class="mt-1 border-2 border-gray-300 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center text-center"
         @drop.prevent="onChange($event.dataTransfer.files); dragLeave()"
         @dragover.prevent="dragOver"
         @dragleave.prevent="dragLeave">
      <div class="space-y-1" >
      <label for="file-upload" class="flex flex-col items-center cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 text-dropzone-color dark:text-white" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <span class="font-normal text-sm  dark:bg-primaryDark text-black leading-6 text-gray-500" accept="image/x-png,image/gif,image/jpeg" @change="$refs.fileInput.click()">
                        <span class="font-semibold">Click to upload</span> or Drag and drop <br /> SVG, PNG, JPG or GIF 
                    </span>
                    <input id="file-upload"  name="file-upload" type="file" class="sr-only" multiple>
                   </label> 
      </div>
    </div>
    <div v-if="preview" class="h-20 flex flex-wrap justify-center mt-2">
      <div v-for="(pic, idx) in previews" :key="idx" class="h-16 w-16 mx-2 my-2">
        <img :src="pic" class="h-full object-cover" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageInput",
  props: {
    heading: String,
    onChange: Function,
    logoPreview: String,
    previews: Array,
    preview: Boolean
  },
  methods: {
    dragOver(){
     this.$refs.dropbox.classList.add('active')
    },
    dragLeave(){
      this.$refs.dropbox.classList.remove('active')
    }
  }
}
</script>
<style scoped>
  .active{
    border-color: aqua;
  }

</style>
