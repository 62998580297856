<template>
    <div class="flex flex-col justify-center items-center">
      <div class="flex mx-auto rounded-full h-32 w-32 flex-col items-center justify-center"
          >
        <div @click="$refs.file.click()" v-if="logoPreview === '' || !logoPreview" class="rounded-full h-3/4 w-auto flex items-center justify-center text-center bg-gray-300 transition duration-200 hover:bg-indigo-200"><p>Upload profile picture</p></div>
        <img @click="$refs.file.click()" v-else :src="logoPreview" class="inline object-cover rounded-full h-full w-full flex items-center justify-center object-cover transition duration-200 transform hover:opacity-80">
        <input @change="fileChange($event.target.name, $event.target.files)" name="file-input" ref="file" type="file" class="hidden" />
      </div>
    <button @click="$refs.file.click()" :class="buttonClasses"
        class="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium
        rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 
        dark:bg-backgroundDark dark:text-white"
        >Change Picture</button>
    </div>
</template>

<script>
export default {
  name: "ImageCircleInput",
  props:{
    logoPreview: String,
    fileToBig: Boolean,
    fileChange: Function,
    version: {
      required: false,
      default: "1"
    }
  },
  computed: {
    buttonClasses(){
      if(this.version) return "text-grey-600 focus:ring-gray-500";
      return "text-indigo-700 focus:ring-indigo-500";
    }
  }
}
</script>
