<template>
<div class="grid grid-cols-7 h-full" :class="[people.length > 0 && !selectedPerson.id ? 'grid-rows-'+people.length : 'grid-rows-1']">
  <img v-if="!selectedPerson.id" src="@/assets/avatar_placeholder.png" class="col-span-1 w-full p-2 rounded-full self-center flex items-center justify-center">
  <img v-else :src="selectedPerson.image" class="col-span-1 w-full p-2 rounded-full self-center flex items-center justify-center">
  <input :disabled="inputDisabled" v-model="searchString" @input="onSearch($event.target.value)" class="col-span-3  w-full border-none bg-transparent text-2xl tracking-widest border-transparent focus:outline-none focus:border-none" placeholder="Name" />
  <div v-for="person in people" :key="person.id" class="col-start-5 col-span-2 ">
    <MemberAvatar  @select="selectPerson" v-if="!selectedPerson.id" :person="person"   />
  </div>
</div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import MemberAvatar from "@/components/widgets/entityMemberWidget/MemberAvatar";

export default {
  name: "ProfileSearchWidget",
  components: {MemberAvatar},
  props: {
    profile: Object
  },
  setup(props, { emit }) {
    const store = useStore()
    const searchString = ref('')
    const selectedPerson = ref(props.profile)
    const inputDisabled = ref(false)

    if(props.profile.id){
      onProvidedProfile(props.profile)
    }

    function onProvidedProfile(profile){
      searchString.value = profile.fullName
      selectedPerson.value.image = profile.profilePhoto
      inputDisabled.value = true
    }

    async function onSearch(str){
      searchString.value = str
      selectedPerson.value.id = null
      emit('selected', null)
      await store.dispatch('fetchPeople', {name: searchString.value, first: 4, after:'', before:''})
    }

    function selectPerson(person){
      selectedPerson.value = {...person}
      searchString.value = person.name
      emit('selected', {...selectedPerson.value})
    }

    return {
      inputDisabled,
      searchString,
      selectedPerson,
      onSearch,
      selectPerson,
      people: computed(() => store.getters.getCardFormattedPeople),
    }
  }
}
</script>
