<template>
  <div class="flex">
    <div class="flex-col w-1/2">
      <div class="flex justify-start my-6">
        <div @click="currentComponent = 2" class="bg-gray-300 rounded-md w-36 h-36 mx-6 flex items-center justify-center"><p class="text-center tracking-wide text-2xl">Upload photos</p></div>
        <div @click="currentComponent = 3" class="bg-gray-300 rounded-md w-36 h-36 mx-6 flex items-center justify-center"><p class="text-center tracking-wide text-2xl">Link video</p></div>
      </div>
      <div class="flex justify-start my-6 w-full">
        <transition-group name="fade">
          <MultiplePhotoForm v-if="currentComponent === 2" :key="2" :on-submit="submitMultiplePhoto"/>
          <VideoForm v-if="currentComponent === 3" :key="3" :on-submit="submitVideo" />
        </transition-group>
      </div>
    </div>
    <div class="w-1/2">
      <PhotoGallery class="" :images="images" />
    </div>

  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import { notify } from "@kyvg/vue3-notification";
import MultiplePhotoForm from "@/components/forms/MultiplePhotoForm";
import VideoForm from "@/components/forms/VideoForm";
import PhotoGallery from "@/components/widgets/photoGallery/PhotoGallery";
export default {
  name: "ProductionMedia",
  components: {PhotoGallery, VideoForm, MultiplePhotoForm},
  setup() {
    const store = useStore()
    const currentComponent = ref(0)

    async function submitMultiplePhoto(data){
      for(let i = 0; i < data.length; i ++) {
        await store.dispatch('createProductionImage', {
          productionId:store.getters.getProductionId,
          location: data[i].location,
          date: data[i].date,
          photographer: data[i].photographer,
          image: data[i].image
        })
      }
      notify({
        title: "Success",
        text: "Your photos have been saved!",
        type: 'success',
        duration: 2000
      })
    }
    async function submitVideo(data){
      await store.dispatch('createProductionVideo', {...data, productionId:store.getters.getProductionId})
      notify({
        title: "Success",
        text: "Your video has been saved!",
        type: 'success',
        duration: 2000
      })
    }

    return {
      currentComponent,
      submitMultiplePhoto,
      submitVideo,
      images: computed(() => store.getters.getProductionImages),
    }
  }
}
</script>
