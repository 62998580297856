<template>
  <div class="flex flex-col bg-white shadow-lg w-full">
    <div class="flex justify-center py-6 space-x-4 ">
      <div >
      <SmallTextInput placeholder="Link" :value="form.link" @update:value="form.link=$event" />
        <div v-if="notValid" class="text-red-500">Please use a valid Youtube url</div>
      </div>
      <SmallTextInput placeholder="Name" :value="form.name" @update:value="form.name=$event" :errors="v$.name.$errors"/>
    </div>
    <div class="flex m-6 justify-center space-x-4">
      <SmallTextInput placeholder="Producer" :value="form.producer" @update:value="form.producer=$event" :errors="v$.producer.$errors"/>
      <DateInput :value="form.date" @update:value="form.date=$event" :errors="v$.date.$errors" />
      <SmallTextInput placeholder="Location" :value="form.location" @update:value="form.location=$event" :errors="v$.location.$errors"/>
    </div>
    <div class="flex justify-end items-center my-2">
      <button  class="mx-2 bg-indigo-white border border-2 border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Cancel</button>
      <button @click="submitForm" class="mx-2 bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Save</button>
    </div>
  </div>
</template>

<script>
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import {reactive} from "vue";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "VideoForm",
  components: {DateInput, SmallTextInput},
  props: {
    onSubmit: Function
  },
  data(){
    return{
      notValid: false,
    }
  },
  setup() {
    const form = reactive({
      link: '',
      name: '',
      producer: '',
      date: '',
      location: '',
    })

    const rules = {
      name: { required },
      producer: { required },
      date: { required },
      location: { required },
    }
    const v$ = useVuelidate(rules, form, {$lazy: true})
    return {
      form,
      v$,
    }
  },
  methods: {
    validateUrl(urlToParse){
    if (urlToParse) {
    const youtube = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    //const vimeo = /^(http?:\/\/|https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/;
    if (urlToParse.match(youtube)) {
      return true;
    }
  }
  return false;
},
    async submitForm(e) {
      const valid = this.validateUrl(this.form.link)
      this.v$.$touch()
      if(valid){
        this.notValid = false
        if (this.v$.$error) return
      await this.$props.onSubmit(this.form)
        this.form.link = '';
        this.form.name = '';
        this.form.producer = '';
        this.form.date = '';
        this.form.location = '';
        e.preventDefault();
    }
    else{
      this.notValid = true
      }
    }}
}
</script>

<style scoped>

</style>