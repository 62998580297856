<template>
  <div @click="$emit('select', person)" class="p-4 flex border-b-2 shadow-lg">
    <img class="p-2 w-1/2 rounded-full" :src="person.image" alt="" />
    <div class="ml-3 flex justify-center items-center">
      <p class="text-sm font-medium text-gray-900">{{ person.name }}</p>
<!--      <p class="text-sm text-gray-500">{{ person.email }}</p>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberAvatar",
  props: {
    person: Object
  }
}
</script>

<style scoped>

</style>