<template>
  <div class="grid grid-cols-10">
    <CreationStepIndicator
        title="New production"
        sub-title="Please fill out the production's information below"
        :total-steps="5"
        :current-step="step"
        class="mx-64 col-span-10"
    />
    <div class="col-start-3 col-span-7">
    <transition-group name="fade">
      <ProductionInfo :create="true" @submitted="onSave" :submit="submit" :key="1" v-if="step === 1"  />
      <ProductionMembers :key="2" v-if="step === 2"  />
      <ProductionMedia :key="3" v-if="step === 3" />
      <ProductionDocuments :key="4" v-if="step === 4" />
    </transition-group>
    </div>


    <div v-if="step !== 1" class="flex justify-end col-span-9 mt-2">
      <button v-if="step === 1" @click="router.push({name: 'Productions'})" class="mx-2 bg-indigo-white border border-2 border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Cancel</button>
      <button v-if="step > 1" @click="paginate(step-1)" class="mx-2  bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Back</button>
<!--      <button  @click="onSubmit" class="mx-2 bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Save and continue</button>-->
      <button v-if="step !== 1" @click="paginate(step+1)" class="mx-2  bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Save and continue</button>
    </div>
  </div>

</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import {useRouter} from "vue-router";
import CreationStepIndicator from "@/components/widgets/creationStepIndicator/CreationStepIndicator";
import ProductionInfo from "@/components/productions/editProduction/ProductionInfo";
import ProductionMembers from "@/components/productions/editProduction/ProductionMembers";
import ProductionMedia from "@/components/productions/editProduction/ProductionMedia";
import ProductionDocuments from "@/components/productions/editProduction/ProductionDocuments";

export default {
  name: "AddProduction",
  components: {ProductionMedia, ProductionMembers, CreationStepIndicator, ProductionInfo, ProductionDocuments },
  setup(){
    const store = useStore()
    const router = useRouter()
    store.commit('resetProduction')
    store.dispatch('fetchWorkTypes')

    let step = ref(1)
    let submit = ref(false)
    return {
      store,
      router,
      step,
      submit,
      paginate(page){
        step.value = page
      },
      onSubmit(){
        if (store.getters.getProductionId) {
          step.value ++
        } else {
          submit.value = true
          setTimeout(() => {
            submit.value = false
          }, 100)
        }
      },
      async onSave(form){
        const productionId = store.getters.getProductionId
        if(!productionId){
          await store.dispatch('createProduction', form)
        } else {
          await store.dispatch('saveProduction', {...form, productionId:productionId})
        }
        step.value++
      }
    }
  },
  beforeUnmount() {
    this.$store.commit('resetProduction')
  }
}
</script>
