<template>
<div class="h-full">
  <button @click="modalOn=true" type="button" class="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
    Upload Document
  </button>
  <DocumentForm @close="modalOn=false" v-if="modalOn" :on-submit="onSubmit" />
  <DocumentTable  @search="onSearch" :documents="documents" :headers="headers" />
</div>
</template>

<script>
import {useStore} from "vuex";
import { notify } from "@kyvg/vue3-notification";
import {computed, ref} from "vue";

import DocumentForm from "@/components/forms/DocumentForm";
import DocumentTable from "@/components/widgets/documentTable/DocumentTable";
export default {
  name: "ProductionDocuments",
  components: {DocumentTable, DocumentForm },
  setup(){
    const store = useStore()
    const modalOn = ref(false)

    return {
      modalOn,
      async onSubmit(data) {
        await store.dispatch('createProductionDocument', {...data, productionId: store.getters.getProductionId})
        notify({
          title: "Success",
          text: "Your document has been saved!",
          type: 'success',
          duration: 2000
        })
        modalOn.value=false
      },
      documents: computed(() => store.getters.getProductionDocuments),
      headers: computed(() => ['title', 'date', 'version', 'documentType', 'department']),
      async onSearch(form){
        await store.dispatch('fetchProductionDocuments', {
          productionId:store.getters.getProductionId,
          documentTitle: form.title,
          documentType: form.type,
          documentDepartment: form.department,
          sortBy: form.sortBy
        })
      }
    }

  }
}
</script>
