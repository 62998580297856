<template>
  <div class="bg-white shadow-lg w-full p-4 my-6">
    <div class=" flex justify-start flex-wrap" >
      <input @change="fileChange($event.target.name, $event.target.files)" class="w-full px-4 py-2" type="file">
      <SmallTextInput class="w-1/3 px-4 py-2" heading="Document no." :value="form.no" @update:value="form.no=$event"/>
      <SmallTextInput class="w-1/3 px-4 py-2" heading="Document title." :value="form.title" @update:value="form.title=$event" :errors="v$.title.$errors"/>
      <SelectInput class="w-1/3 px-4 py-2" heading="Document type" :options="documentTypes" :value="form.documentType" @update:value="form.documentType=$event" :errors="v$.documentType.$errors"/>
      <SmallTextInput class="w-1/3 px-4 py-2" heading="Document version." :value="form.version" @update:value="form.version=$event"/>
      <DateInput heading="Date" class="w-1/3 px-4 py-2" :value="form.date" @update:value="form.date=$event"/>
      <DateInput heading="Valid until" class="w-1/3 px-4 py-2" :value="form.expirationDate" @update:value="form.expirationDate=$event"/>
      <SmallTextInput class="w-1/3 px-4 py-2" heading="Author" :value="form.author" @update:value="form.author=$event"/>
    </div>
    <div class="flex justify-end items-center my-10">
      <button @click="$emit('close')"  class="mx-2 bg-indigo-white border border-2 border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Cancel</button>
      <button @click="submit" class="mx-2 bg-gray-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:text-white dark:bg-backgroundDark" >Save</button>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, reactive} from "vue";
import { required } from '@vuelidate/validators'
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import SelectInput from "@/components/inputs/SelectInput";
import useVuelidate from "@vuelidate/core";
export default {
  name: "DocumentForm",
  components: {SelectInput, DateInput, SmallTextInput},
  props: {
    onSubmit: Function
  },
  setup() {
    const store = useStore()
    const form = reactive({
      file: '',
      no: '',
      title: '',
      documentType: '',
      version: '',
      date: '',
      expirationDate: '',
      author: '',

    })

    const rules = {
      file: { required },
      title: { required },
      documentType: { required },
    }

    const v$ = useVuelidate(rules, form, {$lazy: true})

    return {
      v$,
      form,
      fileChange: (name, files) => {
        form.file = files[0]
      },
      documentTypes: computed(() => store.getters.getDocumentTypes)
    }
  },
  methods: {
    submit(){
      this.v$.$touch()
      if (this.v$.$error) return
      this.$props.onSubmit(this.form)
    },
  }
}
</script>
